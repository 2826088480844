import Button from '@/components/Button';
import { GameTeaserProps, useGameTeaser } from '@/components/GameTeaser';
import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import Modal from '@/components/Modal';
import ModalQuickTipsCouponAmount from '@/components/ModalQuickTipCouponAmount';
import ModalQuickTipDrawRateButtons from '@/components/ModalQuickTipDrawRateButtons';
import ModalQuickTipDrawTypes from '@/components/ModalQuickTipDrawTypes';
import { generateRandomCouponsQueryParams } from '@/helpers/generateRandomCouponsQueryParams';
import { getGameById } from '@/helpers/getGameById';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import classnames from 'classnames';
import { useRouter } from 'next/router';

export type HeaderGameTeaserProps = GameTeaserProps<{
  link: string;
  quickTipParams?: string;
}>;

interface IParamItem {
  value: number | string;
  paramName?: string;
}

interface IDrawSettings {
  drawRate: IParamItem;
  drawType: IParamItem;
}

const HeaderGameTeaser = (props: HeaderGameTeaserProps) => {
  const router = useRouter();
  const { config, state, theme } = useGameTeaser(props);

  const _drawSettings: IDrawSettings = {
    drawRate: {
      value: '',
      paramName: 'ba',
    },
    drawType: {
      value: '',
    },
  };

  const setDrawRate = (value: number | string) => {
    _drawSettings.drawRate.value = value;
  };

  const setDrawType = (value: number | string) => {
    _drawSettings.drawType.value = value;
  };

  const drawType = _drawSettings.drawType.value;

  const drawSettings = _drawSettings;

  const getDrawSettingsQueryParams = (): string => {
    return Object.keys(drawSettings).reduce((previousValue, currentValue) => {
      const { paramName, value } = drawSettings[currentValue];
      return value && paramName
        ? `${previousValue}&${paramName}=${value}`
        : previousValue;
    }, '');
  };

  const redirect = (amountFields: number): void => {
    router.push(
      props.link +
        '?' +
        generateRandomCouponsQueryParams(amountFields, +drawType) +
        getDrawSettingsQueryParams()
    );
  };

  const couponGroup = config ? config.couponGroup : null;
  const amountCoupons = couponGroup ? couponGroup.amountCoupons || 0 : 0;

  const gameConfig = getGameById(props.game);

  const handleOnButtonClick = (): void => {
    if (amountCoupons !== 0) {
      Modal.open(
        gameConfig && gameConfig.drawTypes
          ? 'modal-quick-tip-draw-types'
          : 'modal-quick-tip-header-coupon-amount'
      );
    } else {
      redirect(0);
    }
  };

  const logo = ((): LogoVariant | undefined => {
    if (config) {
      return (
        (config.alternativeDesign &&
          config.alternativeDesign.productLogoHeader) ||
        (config.alternativeDesign &&
          config.alternativeDesign.productLogoNavigation) ||
        config.logo
      );
    }
    return undefined;
  })();

  const hasAlternativeDesign = Boolean(config && config.alternativeDesign);

  return config ? (
    <div
      className={classnames('HeaderGameTeaser', `HeaderGameTeaser--${theme}`, {
        'HeaderGameTeaser--alternative': hasAlternativeDesign,
      })}
    >
      {config.shortname === 'keno' && (
        <div className="HeaderGameTeaser__campaign-title">
          Jeder Tag ist ein Millionen-Tag.
        </div>
      )}
      {config.shortname !== 'keno' && (
        <h3>
          {logo && (
            <Logo
              className="HeaderGameTeaser__logo"
              variant={logo}
              title={`${config.title} Lotterie-Übersicht`}
            />
          )}
        </h3>
      )}
      {theme === 'rubbellose' && (
        <strong
          className="HeaderGameTeaser__subtitle"
          dangerouslySetInnerHTML={replaceAbbreviations(state.jackpot)}
        />
      )}
      {theme !== 'rubbellose' && (
        <header className="HeaderGameTeaser__header">
          <span
            className="HeaderGameTeaser__subtitle"
            dangerouslySetInnerHTML={replaceAbbreviations(state.subtitle)}
          />
          <strong className="HeaderGameTeaser__jackpot">{state.jackpot}</strong>
          <small
            className="HeaderGameTeaser__chance"
            dangerouslySetInnerHTML={replaceAbbreviations(config.chanceDesc)}
          />
        </header>
      )}
      <section className="HeaderGameTeaser__buttons">
        {props.link && (
          <LinkWrapper
            className="HeaderGameTeaser__link"
            tabIndex={-1}
            href={props.link}
          >
            <Button variant={config.button} fullWidth={true}>
              {config.title} spielen
            </Button>
          </LinkWrapper>
        )}
        {props.quickTipParams && (
          <Button
            className="HeaderGameTeaser__link"
            variant={config.buttonSecondary}
            iconPosition={Button.IconPosition.Left}
            fullWidth={true}
            onClick={handleOnButtonClick}
          >
            <Icon variant={IconVariant.QuickTips} />
            <span>Quick-Tipp abgeben</span>
          </Button>
        )}
      </section>
      {amountCoupons !== 0 && (
        <>
          {gameConfig && (
            <>
              {gameConfig.drawTypes && (
                <ModalQuickTipDrawTypes
                  id={'modal-quick-tip-draw-types'}
                  drawTypes={gameConfig.drawTypes}
                  onClickButton={(value: number) => {
                    setDrawType(value);
                    Modal.open('modal-quick-tip-header-draw-rate');
                  }}
                />
              )}

              {gameConfig.drawRate && (
                <ModalQuickTipDrawRateButtons
                  id="modal-quick-tip-header-draw-rate"
                  drawRateList={gameConfig.drawRate}
                  onClickButton={(value) => {
                    setDrawRate(value);
                    Modal.open('modal-quick-tip-header-coupon-amount');
                  }}
                />
              )}
            </>
          )}

          <ModalQuickTipsCouponAmount
            id="modal-quick-tip-header-coupon-amount"
            amountCoupons={amountCoupons}
            onClickButton={redirect}
          />
        </>
      )}
    </div>
  ) : null;
};

export default HeaderGameTeaser;
